import { cn } from "@/lib/utils"
import { Slot } from "@radix-ui/react-slot"
import { cva } from "class-variance-authority"
import * as React from "react"

const buttonVariants = cva(
    "inline-flex items-center justify-center whitespace-nowrap rounded-lg text-sm font-medium transition-colors focus-visible:outline focus-visible:outline-offset-1 focus-visible:outline-black/20 disabled:pointer-events-none disabled:opacity-50 duration-200 ease-out transition",
    {
        variants: {
            variant: {
                default:
                    "bg-brand-light text-white hover:ring-brand-light/30 disabled:bg-stone-300 disabled:text-stone-400 hover:ring-[3px] hover:ring-brand-light/30",
                destructive: "bg-red-500 text-stone-50 hover:bg-red-500/90 hover:ring-[3px] hover:ring-red-500/30",
                outline: "border border-stone-200 bg-white hover:bg-stone-100 hover:text-stone-900 hover:ring-[3px] hover:ring-stone-200/50",
                secondary: "bg-stone-100 text-stone-900 hover:bg-stone-100/80 dark:bg-stone-800 hover:ring-[3px] hover:ring-stone-100/30",
                ghost: "hover:bg-stone-100 hover:text-stone-900",
                link: "text-stone-900 underline-offset-4 hover:underline",
            },
            size: {
                default: "h-10 px-4 py-2",
                sm: "h-9 rounded-lg px-3",
                lg: "h-11 rounded-lg px-8",
                icon: "h-10 w-10",
            },
        },
        defaultVariants: {
            variant: "default",
            size: "default",
        },
    },
)

const Button = React.forwardRef(({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />
})
Button.displayName = "Button"

export { Button, buttonVariants }
